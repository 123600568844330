import React from "react";
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from 'react-bootstrap';
import styled from "styled-components";
import Seo from "../components/Seo"

const PromotionWrapper = styled.div`
    border-bottom: 3px solid #720000;
    margin-bottom: 15px;
    h3 {
        font-size: 28px;
        margin-bottom: 15px;
    }
`

const Promotion = () => {
    return (
    <Layout>
        <Seo 
            title="Promotion สำหรับลูกค้าเรา | Kickoffx999"
            description="Promotion สำหรับลูกค้าเรา Kickoffx999"
            image=""
            url="https://kickoffx999.com/promotion/"
            canonical="https://kickoffx999.com/promotion/"
        />
        <StaticImage src="../images/hero-banner.png" alt="hero-banner" />
        <Container className="py-5">
            <Row>
                <Col md={12}>
                    <PromotionWrapper>
                        <h3>โปรโมชั่น <span>สำหรับลูกค้าเรา</span></h3>
                    </PromotionWrapper>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <StaticImage src="../images/promotion-02.jpg" alt="promotion-banner" />
                </Col>
            </Row>
            <Row>
                
            </Row>
        </Container>
    </Layout>
)};

export default Promotion;